import React from 'react';
import mixpanel from 'lib/Analytics/Mixpanel';
import { useCheckSession } from 'components/Session';
import hasAccess from 'components/Helpers/brandHasAccess';
import { toNewCampaign } from 'constants/routes';

const ModalDetails = ({ avatar, creator }) => {
  const hasSession = useCheckSession();
  const show = hasAccess();

  function trackTo(e) {
    e.preventDefault();
    if (mixpanel) {
      mixpanel.track('Onboarding from Content Modal', { first: hasSession });
    }
    toNewCampaign();
  }

  return (
    <div id="creator-details" className="creator-details">
      <div className="creator-container">
        <img src={avatar} alt="" />
        <span>
          <h4>{creator}</h4>
          <p>Start a content project to collect applications from creators like {creator}</p>
        </span>
      </div>
      {show && (
        <button className="u-button-reset" onClick={trackTo}>
          Start Content Project
        </button>
      )}
    </div>
  );
};

export default ModalDetails;
