import React from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from 'images/ContentShowcase/Close_x.svg';
import ModalDetails from 'components/ContentShowcase/ContentModalDetails';

/**
 * @typedef {{
 *  modalData: Object,
 *  closeModal: () => any
 * }} ContentModalProps
 *
 * @type {React.FC<ContentModalProps>}
 */

const ContentModal = ({ modalData, closeModal }) => {
  const { content, creator, type, avatar } = modalData;

  return createPortal(
    <div className="content-modal">
      <div
        className="content-modal-body"
        role="dialog"
        aria-modal="true"
        aria-labelledby="content-modal-heading"
        aria-describedby="creator-details"
      >
        <button className="u-button-reset close-btn" onClick={() => closeModal()}>
          <CloseIcon />
        </button>
        <h3 id="content-modal-heading">Trend creator content</h3>
        <div className="asset-container">
          {type === 'image' ? (
            <img src={content} alt="Trend creator content" />
          ) : (
            <video controls autoPlay>
              <source src={content} type="video/mp4" />
              <source src={content} type="video/webm" />
              <source src={content} type="video/ogg" />
              <source src={content} type="video/avi" />
              Sorry, your browser doesn’t support embedded videos.
            </video>
          )}
        </div>
        <ModalDetails id="creator-details" avatar={avatar} creator={creator} />
      </div>
    </div>,
    document.body
  );
};

export default ContentModal;
