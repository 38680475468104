import React from 'react';
import { Link } from 'react-router-dom';

const SidebarLink = ({ icon, isNew = false, label, routeUrl }) => {
  const isExternal = routeUrl.includes('http');
  const isActive = window.location.pathname === routeUrl;
  const className = isActive ? 'sidebar-link sidebar-link--active' : 'sidebar-link';

  return isExternal ? (
    <a className={className} href={routeUrl} target="_blank" rel="noopener noreferrer">
      {icon}
      <span className="sidebar-link-copy">{label}</span>
      {isNew && <span className="new-pill">New</span>}
    </a>
  ) : (
    <Link className={className} to={routeUrl}>
      {icon}
      <span className="sidebar-link-copy">{label}</span>
      {isNew && <span className="new-pill">New</span>}
    </Link>
  );
};

export default SidebarLink;
