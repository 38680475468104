import React, { useEffect, useState } from 'react';
import { Spin, Icon } from 'antd';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { firebase } from '../../lib/Firebase';

const ImageUploader = ({ children, onChange, value }) => {
  const [image, setImage] = useState(value);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setImage(value);
  }, [value]);

  const handleFileUpload = e => {
    e.preventDefault();

    const file = e.target.files[0];

    if (!file) return;

    setLoading(true);

    const storageRef = ref(firebase.storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      error => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          setImage(downloadURL);
          onChange(downloadURL);
          setLoading(false);
        });
      }
    );
  };

  return (
    <div className="image-uploader">
      {loading ? (
        // loading state
        <Spin tip={`${progress}%`} spinning={loading} />
      ) : image ? (
        // image has been uplaoded
        <>
          <img src={image} className="uploaded-image" alt={image} />
          <label className="ant-btn trend-btn change-photo-label" tabIndex={0}>
            Change Photo
            <input
              onChange={handleFileUpload}
              accept="image/*"
              type="file"
              style={{ visibility: 'hidden', width: '1px' }}
            />
          </label>
        </>
      ) : (
        // no image has been uploaded
        <label className="uploader-label">
          <Icon type="plus" className="icon" />
          <input
            onChange={handleFileUpload}
            accept="image/*"
            type="file"
            style={{ visibility: 'hidden' }}
          />
          {children}
        </label>
      )}
    </div>
  );
};

export default ImageUploader;
