import React, { useEffect, useState } from 'react';
import { useSession } from 'components/Session';
import { notification } from 'antd';
import { brandUpdateAccount } from 'lib/Firebase/callables/brands';
import ImageUploader from 'components/Form/ImageUploader';

/**
 * @typedef {{}} CompanyProps
 * @type {React.FC<CompanyProps>}
 */
const CompanyPage = () => {
  const [companyDescription, setCompanyDescription] = useState('');
  const [companyImage, setCompanyImage] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [imageError, setImageError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    brand: { name, description, storeImage },
  } = useSession();

  useEffect(() => {
    setCompanyName(name);
    setCompanyDescription(description);
    setCompanyImage(storeImage);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!companyImage) {
      setImageError('Please upload an image');

      return;
    }

    setIsSubmitting(true);
    setImageError('');

    // TODO: CHECK TO MAKE SURE THIS WORKS
    try {
      await brandUpdateAccount({
        description: companyDescription,
        logo: companyImage,
        name: companyName,
        storeImage: companyImage,
      });

      notification.success({
        message: 'Company',
        description: 'Company settings saved',
        duration: 3,
      });
    } catch (e) {
      const feedbackMessage =
        String(e.response.data.message) || String(e.message) || 'Something went wrong';

      notification.error({
        message: 'Error',
        description: feedbackMessage,
        duration: 3,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <article className="company-page">
      <form name="company-form" onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <ImageUploader value={companyImage} onChange={e => setCompanyImage(e)}>
            <span>Add an image that represents your brand</span>
          </ImageUploader>
          {imageError && <span className="error">{imageError}</span>}
        </div>
        <div className="input-wrapper">
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            placeholder="Trend"
            value={companyName}
            required
            onChange={e => setCompanyName(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <label>Company Description</label>
          <textarea
            name="companyDescription"
            placeholder="Describe your company in 2-3 sentences"
            rows="5"
            value={companyDescription}
            required
            onChange={e => setCompanyDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="company-page-footer">
          <button className="u-button-reset save-changes-btn" type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <svg
                className="u-loading-indicator"
                fill="currentColor"
                viewBox="0 0 1024 1024"
                focusable="false"
                height="24px"
                width="24px"
              >
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
              </svg>
            ) : (
              'Save Updates'
            )}
          </button>
        </div>
      </form>
    </article>
  );
};

export default CompanyPage;
