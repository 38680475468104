import React from 'react';
import { List, Col, Row } from 'antd';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';

const AdminOptions = props => {
  const { cookies } = props;
  const isAdmin = cookies.get('isAdmin');
  const options = isAdmin
    ? [
        { name: 'Brands', path: routes.ADMIN_BRANDS_PATH },
        { name: 'Admin V2', path: routes.ADMINV2_PATH },
        { name: 'Content', path: routes.ADMINV2_CONTENTS_PATH },
      ]
    : [{ name: 'Content', path: routes.ADMINV2_CONTENTS_PATH }];

  return (
    <div className="min-layout-content bg-white">
      <h1>Employee Functions</h1>
      <List
        bordered
        dataSource={options}
        renderItem={option => (
          <List.Item>
            <Link to={option.path}>
              <Row style={{ width: '100%' }}>
                <Col span={24}>{option.name}</Col>
              </Row>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
};

export default withCookies(AdminOptions);
