import React from 'react';
import { ReactComponent as InstagramIcon } from 'images/components/Products/Details/Instagram.svg';
import { ReactComponent as TiktokIcon } from 'images/components/Products/Details/Tiktok.svg';
import { ReactComponent as FacebookIcon } from 'images/components/Products/Details/Facebook.svg';
import { ReactComponent as AmazonIcon } from 'images/components/Products/Details/Amazon.svg';
import { ReactComponent as GeneralUseIcon } from 'images/components/Products/Details/GeneralUse.svg';
import { ReactComponent as YoutubeIcon } from 'images/components/Products/Details/Youtube.svg';
import { CAMPAIGN_PLATFORM, CAMPAIGN_TYPE } from 'constants/campaigns';
import Gender from './Gender';

const PLATFORM_ICON = {
  [CAMPAIGN_PLATFORM.INSTAGRAM]: InstagramIcon,
  [CAMPAIGN_PLATFORM.TIKTOK]: TiktokIcon,
  [CAMPAIGN_PLATFORM.FACEBOOK]: FacebookIcon,
  [CAMPAIGN_PLATFORM.AMAZON]: AmazonIcon,
  [CAMPAIGN_PLATFORM.YOUTUBE]: YoutubeIcon,
  [CAMPAIGN_PLATFORM.GENERAL_USE]: GeneralUseIcon,
};

const appendHttpProtocol = link => (/http/.test(link) ? link : `http://${link}`);

/**
 * @typedef {{product: import('components/Session/Context').Product}} DetailsProps
 * @type {React.FC<DetailsProps>}
 */
const Details = ({ product }) => {
  const { url, platform, value, category, type, styleCategory } = product;

  const PlatFormIcon = PLATFORM_ICON[platform];

  const description =
    styleCategory && styleCategory.length
      ? `This is a ${
          type === CAMPAIGN_TYPE.IMAGE ? 'photography' : 'video content'
        } campaign. Each creator you activate will produce ${styleCategory.length} ${
          type === CAMPAIGN_TYPE.IMAGE ? 'photos' : 'videos'
        } for you.`
      : null;

  return (
    <div id="campaignDetails">
      <h3 className="side-header-text">Campaign Details</h3>
      {description && <p className="side-text-description">{description}</p>}

      <h4 className="side-sub-header-text">Website</h4>
      <a
        className="side-text-description side-text-description--link"
        href={appendHttpProtocol(url)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {url}
      </a>

      <h4 className="side-sub-header-text">Product Value</h4>
      <p className="side-text-description">${value.toLocaleString('en', { useGrouping: true })}</p>

      <h4 className="side-sub-header-text">Product Category</h4>
      <p className="side-text-description side-text-description--capitalize">{category}</p>

      {PlatFormIcon && type === CAMPAIGN_TYPE.VIDEO && (
        <>
          <h4 className="side-sub-header-text">Videos Formatted For</h4>
          <p className="side-text-description side-text-description--platform">
            <PlatFormIcon style={{ width: '21px', height: '21px', marginRight: '8px' }} />
            {platform.split(/(?=[A-Z])/).join(' ')}
          </p>
        </>
      )}

      <Gender product={product} />
    </div>
  );
};

export default Details;
