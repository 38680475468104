import React, { useState } from 'react';
import { notification } from 'antd';
import { firebase } from 'lib/Firebase';
import { useSession } from 'components/Session';
import { brandUpdateAccount } from 'lib/Firebase/callables/brands';
import { SettingsBackButton } from './SettingsBackButton';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';

/**
 * Update Email Page
 *
 * @type {React.FC<UpdateEmailProps>}
 */
const UpdateEmail = () => {
  const {
    brand: { authEmail },
  } = useSession();

  const [emailError, setEmailError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleEmailChange(e) {
    if (emailError && /\S+@\S+\.\S+/.test(e.target.value)) setEmailError('');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    const email = e.target.email.value;
    const password = e.target.password.value;

    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address');
      setIsSubmitting(false);

      return;
    }

    try {
      // Retest auth before updating email
      const creds = firebase.app.auth.EmailAuthProvider.credential(authEmail, password);
      await firebase.auth.currentUser.reauthenticateWithCredential(creds);

      // Update email
      await brandUpdateAccount({ authEmail: email });

      notification.success({
        message: 'Success',
        description: 'Email address was successfully updated!',
        duration: 4,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        duration: 6,
      });
    } finally {
      setEmailError('');
      setIsSubmitting(false);
    }
  }

  return (
    <article className="update-email">
      <SettingsBackButton />
      <TrendLogoWeb className="logo" width="120px" height="60px" />
      <h1>Change Email</h1>
      <h2>{authEmail}</h2>
      <form name="email-form" onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <label>New Email</label>
          <input type="text" name="email" required onChange={handleEmailChange} />
          {emailError && <span>{emailError}</span>}
        </div>
        <div className="input-wrapper">
          <label>Current Password</label>
          <input type="password" name="password" required />
        </div>
        <button className="u-button-reset save-changes-btn" type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <svg
              className="u-loading-indicator"
              fill="currentColor"
              viewBox="0 0 1024 1024"
              focusable="false"
              height="24px"
              width="24px"
            >
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
            </svg>
          ) : (
            'Save Updates'
          )}
        </button>
      </form>
    </article>
  );
};

export default UpdateEmail;
