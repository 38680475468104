import React, { useState } from 'react';
import ImageKitImage from 'components/Common/ImageKitImage';
import Video from 'components/Common/Video';
import VideoPreviewModal from 'components/Common/VideoPreviewModal';
import { STYLE_GUIDE_TYPE } from 'constants/campaigns';

/**
 * Styleguide Component
 *
 * @typedef {{ product: import('components/Session/Context').Product }} StyleguideProps
 * @type {React.FC<StyleguideProps>}
 */
const Styleguides = ({ product }) => {
  const { styleGuide = {}, styleGuideLink = [] } = product;
  const [previewModal, setPreviewModal] = useState(false);
  const [videoLink, setVideoLink] = useState();

  const list = Object.keys(styleGuide).map((uid, idx) => {
    const guide = styleGuide[uid];
    const isImg = guide.type !== STYLE_GUIDE_TYPE.VIDEO;
    const id = `${uid}-${idx}`;

    return (
      <div key={id} className="style-guide-asset">
        {!isImg && (
          <div
            role="button"
            tabIndex="0"
            className="video-mask"
            onClick={() => {
              setVideoLink(guide.url);
              setPreviewModal(true);
            }}
          />
        )}
        {isImg ? (
          <ImageKitImage path={guide.url} alt={`style guide ${uid}`} />
        ) : (
          <Video src={guide.url} />
        )}
      </div>
    );
  });

  return (
    <section className="style-guide">
      <h3 className="side-header-text">Style Guide</h3>
      {styleGuideLink.length > 0 && (
        <div>
          <h4 className="sub-header-text">Web Links</h4>
          <div className="link-list">
            {styleGuideLink.map(link => (
              <div
                key={link}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `<a class="u-button-reset style-guide-link" href=${link} target="_blank" rel="noopener noreferrer">${link}</a>`,
                }}
              />
            ))}
          </div>
        </div>
      )}
      {list.length > 0 && (
        <div>
          <h4 className={`sub-header-text ${styleGuideLink.length !== 0 && 'adjust-margin'}`}>
            Photos / Videos
          </h4>
          <div className="asset-list">{list}</div>
          <VideoPreviewModal
            setVisible={setPreviewModal}
            visible={previewModal}
            videoLink={videoLink}
          />
        </div>
      )}
    </section>
  );
};

export default Styleguides;
