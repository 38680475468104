import React, { useState } from 'react';
import moment from 'moment';
import { List, Tag, Icon, Spin, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { firebase } from 'lib/Firebase';
import { adminLoginAsBrand } from 'lib/Firebase/callables/admin';

const BrandTable = ({ ids = [], loading }) => {
  const [, setCookies] = useCookies('returnToken');
  const history = useHistory();
  const brands = useSelector(selectors.getBrands());
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = async brandId => {
    setIsLoggingIn(true);

    try {
      const { token, returnToken } = await adminLoginAsBrand(brandId);

      setCookies('returnToken', returnToken, {
        path: '/',
        expires: new Date(
          moment()
            .add(1, 'hour')
            .unix() * 1000
        ),
      });

      await firebase.auth.signInWithCustomToken(token);
      history.push('/');
      setIsLoggingIn(false);
    } catch (e) {
      message.error(e.message);
      setIsLoggingIn(false);
    }
  };

  if (loading || isLoggingIn) {
    return (
      <div style={{ textAlign: 'center', margin: '70px 0 70px' }}>
        <Spin indicator={<Icon type="loading" />} />
      </div>
    );
  }

  return (
    <List
      dataSource={ids}
      renderItem={brandId => {
        const brand = brands[brandId];
        const { name, overallCampaigns = 0, stripeCustomerId, isFreeTrial, isContentAdmin } = brand;

        const actions = [
          <Link className="ant-btn ant-btn-primary" to={`/admin/brands/${brandId}`}>
            Edit
          </Link>,
          <button className="u-button-reset brand-login-btn" onClick={() => handleLogin(brandId)}>
            Login
          </button>,
        ];

        return (
          <List.Item actions={actions}>
            <List.Item.Meta
              title={name || '[ NO NAME ]'}
              description={
                <div>
                  {stripeCustomerId && <Tag color="green">Stripe Id: {stripeCustomerId}</Tag>}
                  {isFreeTrial && <Tag color="orange">Free Trial</Tag>}
                  {overallCampaigns > 0 && <Tag color="volcano">{overallCampaigns} Campaign/s</Tag>}
                  {isContentAdmin && <Tag color="cyan">Content Admin</Tag>}
                </div>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default BrandTable;
