import React from 'react';
import { Layout, Row, Skeleton } from 'antd';

const { Content } = Layout;

/**
 * @type {React.FC}
 */
const Product = () => {
  return ((
    <div>
      <Content>
        <div className="campaign-content">
          <div className="nav-header">
            <Row type="flex" justify="space-around" style={{ height: 153 - 38 }}>
              <div className="min-layout-content">
                <Skeleton active title paragraph={false} />
              </div>
            </Row>
            <div style={{ borderBottom: '1px solid #dbdfe8' }} />
          </div>
        </div>
        <div style={{ paddingBottom: '79px', background: '#ffffff' }}>
          <Row type="flex" justify="space-around">
            <div className="min-layout-content">
              <Row type="flex" align="middle" style={{ marginTop: 30 }}>
                <Skeleton paragraph={4} active title={false} />
                <Skeleton paragraph={5} active />
                <Skeleton paragraph={3} active />
              </Row>
            </div>
          </Row>
        </div>
      </Content>
    </div>
  ));
};

export default Product;
