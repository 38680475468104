import React, { useState } from 'react';
import { notification } from 'antd';
import { firebase } from 'lib/Firebase';
import { useSession } from 'components/Session';
import { SettingsBackButton } from './SettingsBackButton';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';

/**
 * Update Email Page
 *
 * @type {React.FC<UpdatePassword>}
 */
const UpdatePassword = () => {
  const {
    brand: { authEmail },
  } = useSession();

  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleConfirmPasswordChange(e) {
    const confirmPassword = e.target.value;

    if (confirmPassword !== newPassword && confirmPassword.length >= newPassword.length) {
      setConfirmPasswordError('Password does not match');
      return;
    }

    setConfirmPasswordError('');
  }

  function handleConfirmPasswordBlur(e) {
    const confirmPassword = e.target.value;

    if (confirmPassword !== newPassword) {
      setConfirmPasswordError('Password does not match');
      return;
    }

    setConfirmPasswordError('');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    const currentPassword = e.target.currentPassword.value;
    const newPassword = e.target.newPassword.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (confirmPassword !== newPassword) {
      setConfirmPasswordError('Password does not match');
      setIsSubmitting(false);

      return;
    }

    try {
      // Retest auth before updating password
      const creds = firebase.app.auth.EmailAuthProvider.credential(authEmail, currentPassword);
      await firebase.auth.currentUser.reauthenticateWithCredential(creds);

      // Update password
      await firebase.auth.currentUser.updatePassword(confirmPassword);

      notification.success({
        message: 'Success',
        description: 'Password was successfully updated!',
        duration: 4,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        duration: 6,
      });
    } finally {
      setConfirmPasswordError('');
      setIsSubmitting(false);
    }
  }

  return (
    <article className="update-password">
      <SettingsBackButton />
      <TrendLogoWeb className="logo" width="120px" height="60px" />
      <h1>Change Password</h1>
      <h2>{authEmail}</h2>
      <p className="subtitle">
        If you are authenticated with Google or can’t remember your previous password, reach out to{' '}
        <a
          href="mailto:support@trend.io?subject=Password%20Reset%20Help"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@trend.io
        </a>{' '}
        for help resetting your password.
      </p>
      <form name="password-form" onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <label>Current Password</label>
          <input type="password" name="currentPassword" required />
        </div>
        <div className="input-wrapper">
          <label>New Password</label>
          <input
            type="password"
            name="newPassword"
            required
            onChange={e => setNewPassword(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <label>Confirm New Password</label>
          <input
            type="password"
            name="confirmPassword"
            required
            onChange={handleConfirmPasswordChange}
            onBlur={handleConfirmPasswordBlur}
          />
          {confirmPasswordError && <span>{confirmPasswordError}</span>}
        </div>
        <button className="u-button-reset save-changes-btn" type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <svg
              className="u-loading-indicator"
              fill="currentColor"
              viewBox="0 0 1024 1024"
              focusable="false"
              height="24px"
              width="24px"
            >
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
            </svg>
          ) : (
            'Save Updates'
          )}
        </button>
      </form>
    </article>
  );
};

export default UpdatePassword;
