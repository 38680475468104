import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { useCheckSession } from 'components/Session';
import CampaignShowPage from 'pages/Campaigns/ShowPage';
import ContentShowcase from 'pages/Campaigns/ContentShowcase';
import MessagePage from 'pages/Campaigns/ShowPage/MessagePage';
import UpdateEmail from 'pages/UpdateEmail';
import UpdatePassword from 'pages/UpdatePassword';
import SuccessPurchasePage from 'pages/SuccessPurchasePage';
import ExampleCampaigns from 'pages/ExampleCampaigns';
import AdminV2 from 'pages/AdminV2Page';
import Account from 'pages/Account';
import SoonaShowcase from 'pages/Campaigns/SoonaShowcase';
import PasswordForget from '../components/PasswordForget';
import SessionLayout from '../components/Layouts/SessionLayout';
import SiderLayout from '../components/Layouts/SideNavLayout';
import * as routes from '../constants/routes';
import AdminOptions from '../components/Admin';
import AdminBrands from '../pages/Admin/Brands/main';
import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import CampaignHistoryPage from '../pages/Campaigns/HistoryPage';
import AdminLayout from '../components/Layouts/AdminLayout';
import RootPage from '../pages/RootPage';

import Notifications from '../components/Notifications';
import Hubspot from '../components/Hubspot';

const TrendRouters = () => {
  const hasSession = useCheckSession();

  return (
    <div>
      <Router>
        <Switch>
          <AppRoute
            exact
            path={routes.PUBLIC_FORGOT_PASSWORD_PATH}
            component={PasswordForget}
            layout={SessionLayout}
          />

          <PrivateRoute
            path={routes.SOONA_SHOWCASE}
            component={SoonaShowcase}
            layout={SiderLayout}
          />

          <PrivateRoute exact path={routes.ACCOUNT_UPDATE_EMAIL_PATH} component={UpdateEmail} />

          <PrivateRoute
            exact
            path={routes.ACCOUNT_UPDATE_PASSWORD_PATH}
            component={UpdatePassword}
          />

          <PrivateRoute path={routes.ACCOUNT_PATH} component={Account} />
          <PrivateRoute path={routes.SUCCESS_PURCHASE_PATH} component={SuccessPurchasePage} />

          <PrivateRoute
            path={routes.CAMPAIGN_HISTORY_PATH}
            component={CampaignHistoryPage}
            layout={SiderLayout}
          />

          <PrivateRoute
            path="/campaigns/:campaignId/messages/:messageId"
            component={MessagePage}
            layout={SiderLayout}
          />

          <PrivateRoute
            path={routes.CONTENT_SHOWCASE_PATH}
            component={ContentShowcase}
            layout={SiderLayout}
          />

          <PrivateRoute
            path={routes.CAMPAIGN_PATH}
            component={CampaignShowPage}
            layout={SiderLayout}
          />

          <PrivateRoute
            path={routes.EXAMPLE_CAMPAIGNS_PATH}
            component={ExampleCampaigns}
            layout={SiderLayout}
          />

          <AdminRoute
            path={routes.ADMIN_BRANDS_PATH}
            component={AdminBrands}
            layout={SiderLayout}
          />

          <AdminRoute
            exact
            path={routes.ADMIN_OPTIONS_PATH}
            layout={SiderLayout}
            component={AdminOptions}
          />

          <AdminRoute path={routes.ADMINV2_PATH} component={AdminV2} layout={AdminLayout} />
          <PrivateRoute exact path="/" component={RootPage} layout={SiderLayout} />

          {/* route to root if the user goes somewhere unexpected */}
          <AppRoute path="*">
            <Redirect to="/" />
          </AppRoute>
        </Switch>
        {hasSession && <Notifications />}
        {hasSession && <Hubspot />}
      </Router>
    </div>
  );
};

export default TrendRouters;
