import Message from 'components/Messages';
import { useProductData } from 'components/Products';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import EmptyCampaign from 'components/Common/EmptyCampaign';
import NoMessagesImg from 'images/DefaultEmpty/No_Messages.png';

/**
 * @typedef {{}} MessagesProps
 * @type {React.FC<MessagesProps>}
 */
const Messages = () => {
  const { productUID } = useProductData();
  const { read, unRead } = useSelector(selectors.getProductMessages(productUID));

  const allMessages = [...unRead, ...read];

  return (
    <div id="message-container" className="trend-table--wrapper">
      {allMessages.length < 1 ? (
        <EmptyCampaign
          copy="After you approve your creators, you’ll be able to chat with them right here in the platform instead of through DM’s or lengthy email threads."
          heading="No Messages"
          image={NoMessagesImg}
        />
      ) : (
        <Message.List messageUIDs={allMessages} />
      )}
    </div>
  );
};

export default Messages;
