import React from 'react';
import { Modal } from 'antd';

const ImagePreviewModal = ({ setVisible, visible, imageLink }) => {
  return (
    <Modal
      footer={null}
      closable={false}
      wrapClassName="image-preview"
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <img className="standardized-image" src={imageLink} alt="" />
    </Modal>
  );
};

export default ImagePreviewModal;
