import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { useSession } from 'components/Session';
import { brandUpdateAccount } from 'lib/Firebase/callables/brands';
import { ReactComponent as CheckIcon } from 'images/components/Products/Form/CreativeBrief/CheckIcon.svg';
import { ReactComponent as CloseXSmall } from 'images/components/Form/TagSelectV2/CloseXSmall.svg';

const validateEmailAddress = email => {
  const input = document.createElement('input');

  if (email.lastIndexOf('.') < email.lastIndexOf('@')) return false;

  input.type = 'email';
  input.required = true;
  input.value = email;

  if (typeof input.checkValidity === 'function') return input.checkValidity();

  return /\S+@\S+\.\S+/.test(email);
};

const Checkbox = ({ active, handleClick, id }) => {
  if (active) {
    return (
      <button
        id={id}
        className="notifications-checkbox notifications-checkbox--active"
        onClick={handleClick}
      >
        <CheckIcon />
      </button>
    );
  }

  return <button id={id} className="notifications-checkbox" onClick={handleClick} />;
};

const EmailButton = ({ authEmail, email, handleClick }) => {
  if (email === authEmail) return <p className="primary-email">{email}</p>;

  return (
    <div className="secondary-email">
      <p>{email}</p>
      <button className="u-button-reset" onClick={() => handleClick(email)}>
        <CloseXSmall />
      </button>
    </div>
  );
};

// TODO: add this to account creation; update existing accounts to have these keys; remove this logic
const emailInitialState = {
  contentApprovals: true,
  newApplications: true,
  unreadMessages: true,
};

/**
 * @typedef {{}} NotificationsProps
 * @type {React.FC<NotificationsProps>}
 */
const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    brand: { authEmail, emails: accountEmails },
  } = useSession();

  const [emailInput, setEmailInput] = useState('');

  const [emails, setEmails] = useState(
    accountEmails || [
      {
        ...emailInitialState,
        email: authEmail,
      },
    ]
  );

  const [hasChanges, setHasChanges] = useState(false);

  const saveChanges = async () => {
    try {
      setIsLoading(true);

      notification.info({
        message: 'Saving changes...',
        duration: 1,
      });

      await brandUpdateAccount({
        emails,
      });

      setIsLoading(false);
      setHasChanges(false);

      notification.success({
        message: 'Changes saved!',
        duration: 3,
      });
    } catch (err) {
      const feedbackMessage =
        String(err.response.data.message) || String(err.message) || 'Something went wrong';

      notification.error({
        message: 'Error',
        description: feedbackMessage,
        duration: 3,
      });
    }
  };

  const handleEmailChange = e => {
    if (!e.target.value) setHasChanges(false);
    else setHasChanges(true);

    setEmailInput(e.target.value);
  };

  const handleAddEmail = () => {
    if (emailInput.trim().length && validateEmailAddress(emailInput)) {
      setEmails(prevState => [{ ...emailInitialState, email: emailInput }, ...prevState]);
      setEmailInput('');
    }
  };

  const handleRemoveEmail = email => {
    setHasChanges(true);
    setEmails(prevState => prevState.filter(item => item.email !== email));
  };

  const handleEmailInputKeyDown = e => {
    if (e.key === 'Enter') handleAddEmail();
  };

  const handleUpdateEmail = (email, notificationType) => {
    setHasChanges(true);

    setEmails(prevState => {
      return prevState.map(item => {
        if (item.email === email) return { ...item, [notificationType]: !item[notificationType] };

        return item;
      });
    });
  };

  return (
    <article className="brand-notifications">
      <div className="heading">
        <p>Do you want someone on your team to get notified about Trend updates?</p>
        <p>Add additional email addresses to make sure your project is always taken care of.</p>
      </div>
      <div className="add-email-wrapper">
        <label className="u-visually-hidden" htmlFor="add-email">
          Add email
        </label>
        <input
          id="add-email"
          placeholder="Add email"
          type="email"
          value={emailInput}
          onKeyDown={handleEmailInputKeyDown}
          onChange={handleEmailChange}
        />
        <button className="u-button-reset" onClick={handleAddEmail}>
          Add Email
        </button>
      </div>
      {emails.length > 0 &&
        emails.map((email, i) => (
          <div key={`email-notifications-${i}`} className="email-list">
            <EmailButton
              authEmail={authEmail}
              email={email.email}
              handleClick={handleRemoveEmail}
            />
            <ul className="checkbox-list">
              <li>
                <label>
                  <Checkbox
                    active={email.contentApprovals}
                    handleClick={() => handleUpdateEmail(email.email, 'contentApprovals')}
                  />
                  New content to approve
                </label>
              </li>
              <li>
                <label>
                  <Checkbox
                    active={email.newApplications}
                    handleClick={() => handleUpdateEmail(email.email, 'newApplications')}
                  />
                  10+ new applications
                </label>
              </li>
              <li>
                <label>
                  <Checkbox
                    active={email.unreadMessages}
                    handleClick={() => handleUpdateEmail(email.email, 'unreadMessages')}
                  />
                  Daily unread messages digest
                </label>
              </li>
            </ul>
          </div>
        ))}
      <footer>
        <button
          className="u-button-reset save-changes-btn"
          disabled={isLoading || !hasChanges}
          onClick={saveChanges}
        >
          {isLoading ? (
            <svg
              className="u-loading-indicator"
              fill="currentColor"
              viewBox="0 0 1024 1024"
              focusable="false"
              height="24px"
              width="24px"
            >
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
            </svg>
          ) : (
            'Save Updates'
          )}
        </button>
      </footer>
    </article>
  );
};

export default withRouter(Notifications);
