import React from 'react';
import { ReactComponent as ImageIcon } from 'images/components/Products/PostRequirements/ImageType.svg';
import { ReactComponent as VideoIcon } from 'images/components/Products/PostRequirements/VideoType.svg';
import { ReactComponent as InstagramStoryIcon } from 'images/components/Products/PostRequirements/InstagramStory.svg';
import { CAMPAIGN_STYLE_CATEGORIES, STYLE_CATEGORIES } from 'constants/campaigns';

const STYLE_TYPE_LABEL = {
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY]: 'Product Photography',
  [CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY]: 'Portrait Style Photography',
  [CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY]: 'Lifestyle / Product in Action',
  [CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO]: 'Action / Product in Use Video',
  [CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO]: 'Unboxing Explanation Video',
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO]:
    'Product Review / Testimonial Video',
};
/**
 * @param {import('components/Session/Context').Product} product
 * @returns
 */
const requiredPost = product => {
  const { postTypes = [], styleCategory = [], isStoryRequired } = product;

  const determineIcon = val => {
    if (val === STYLE_CATEGORIES.image) {
      return <ImageIcon style={{ width: '20px', height: '20px' }} />;
    }

    return <VideoIcon style={{ width: '20px', height: '16.06px' }} />;
  };

  return (
    <div>
      <h4 className="side-sub-header-text">Posts / Content</h4>
      {postTypes.map((val, i) => {
        const postType = val === 'image' ? 'Image' : 'Video';

        return (
          <ul key={val} className="tag-list">
            <li>
              {determineIcon(val)}
              <span>
                1 {postType} Post
                <span className="caption">
                  {STYLE_TYPE_LABEL[styleCategory[i]] || `${postType} Post`}
                </span>
              </span>
            </li>
          </ul>
        );
      })}

      {isStoryRequired && (
        <p className="post-content">
          <InstagramStoryIcon />1 Instagram Story
        </p>
      )}
    </div>
  );
};

/**
 * @typedef {{ product: import('components/Session/Context').Product }} PostRequirementsProps
 * @type {React.FC<PostRequirementsProps>}
 */
const PostRequirements = ({ product }) => {
  const { hashtags = {}, brandtags = {} } = product;

  return (
    <div className="campaign-post-requirements">
      <h3 className="side-header-text">Post Requirements</h3>
      {requiredPost(product)}

      {Object.values(hashtags).length && (
        <>
          <h4 className="side-sub-header-text">Required Hashtags</h4>
          <ul className="tag-list">
            {Object.values(hashtags).map(tag => (
              <li key={tag}>#{tag}</li>
            ))}
          </ul>
        </>
      )}

      {Object.values(brandtags).length && (
        <>
          <h4 className="side-sub-header-text">Required Brandtags</h4>
          <ul className="tag-list">
            {Object.values(brandtags).map(tag => (
              <li key={tag}>@{tag}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default PostRequirements;
