import { Row, Spin } from 'antd';
import React from 'react';

/**
 * @type {React.FC}
 */
const ExampleList = () => {
  return ((
    <Row
      type="flex"
      justify="space-around"
      align="middle"
      style={{ height: '80vh', textAlign: 'center' }}
    >
      <div className="min-layout-content">
        <Spin />
      </div>
    </Row>
  ));
};
export default ExampleList;
