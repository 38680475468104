import React, { useState } from 'react';
import ImagePreviewModal from 'components/Common/ImagePreviewModal';
import VideoPreviewModal from 'components/Common/VideoPreviewModal';
import ImageKitImage from '../ImageKitImage';

const ContentPreviewModal = ({
  contentType,
  url,
  visible,
  setVisible,
  thumbnailUrl,
  imageKitPreview,
  ikTransformations,
}) => {
  const [fallbackType, setFallbackType] = useState();
  const openModal = () => setVisible(true);

  const onImageError = () => {
    if (!fallbackType) setFallbackType('video');
  };

  const onVideoError = () => {
    if (!fallbackType) setFallbackType('image');
  };

  const renderContent = () => {
    if (imageKitPreview) return <></>;

    if (thumbnailUrl) return <img className="standardized-image" src={thumbnailUrl} alt="" />;

    if (fallbackType === 'image' || contentType === 'image')
      return <img className="standardized-image" src={url} alt="" onError={onImageError} />;

    if (fallbackType === 'video' || contentType === 'video')
      return (
        <div className="video-loader">
          <video
            src={url}
            autoPlay={false}
            preload="auto"
            controls={false}
            onError={onVideoError}
          />
        </div>
      );

    return <></>;
  };

  return (
    <>
      <button className="u-button-reset content-preview-btn" onClick={openModal}>
        {imageKitPreview && (
          <ImageKitImage
            path={url}
            transformation={ikTransformations}
            videoPreview={contentType === 'video'}
          />
        )}
        {renderContent()}
      </button>
      {contentType === 'image' ? (
        <ImagePreviewModal visible={visible} setVisible={setVisible} imageLink={url} />
      ) : (
        <VideoPreviewModal visible={visible} setVisible={setVisible} videoLink={url} autoPlay />
      )}
    </>
  );
};

export default ContentPreviewModal;
