import React from 'react';
import { Row, Col } from 'antd';
import { CAMPAIGN_STYLE_CATEGORIES, STYLE_CATEGORIES, VIDEO_LENGTH } from 'constants/campaigns';
import { POST_TYPES } from 'constants/posts';
import { ReactComponent as Seconds15Icon } from 'images/components/Products/StyleCategory/Seconds15.svg';
import { ReactComponent as Seconds30Icon } from 'images/components/Products/StyleCategory/Seconds30.svg';
import { ReactComponent as Seconds60Icon } from 'images/components/Products/StyleCategory/Seconds60.svg';

const CATEGORY_TEXT = {
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY]: 'Product Photography',
  [CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY]:
    'Lifestyle / Product In Action Photo',
  [CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY]: 'Portrait Style Photography',
  [CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO]: 'Action / Product in Use Video',
  [CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO]: 'Unboxing Demo Video',
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO]:
    'Product Review / Testimonial Video',
};

const VIDEO_DURATION = {
  [VIDEO_LENGTH.FIFTEEN]: { icon: Seconds15Icon, text: 'Up to 15 seconds' },
  [VIDEO_LENGTH.THIRTY]: { icon: Seconds30Icon, text: '15 - 30 seconds' },
  [VIDEO_LENGTH.SIXTY]: { icon: Seconds60Icon, text: '30 - 60 seconds' },
};

/**
 * StyleCategory Component
 *
 * @typedef {{ product: import('components/Session/Context').Product }} InstructionsProps
 * @type {React.FC<InstructionsProps>}
 */
const StyleCategory = ({ product }) => {
  const { styleCategory = [], videoDuration, contentLayout } = product;
  const groupCategories = {};

  styleCategory.forEach(category => {
    groupCategories[category] = groupCategories[category] || 0;
    groupCategories[category] += 1;
  });

  const categoryType = (category, num) => {
    return STYLE_CATEGORIES[POST_TYPES.VIDEO].includes(category)
      ? `Video${num > 1 ? 's' : ''}`
      : `Photo${num > 1 ? 's' : ''}`;
  };

  const uniqCategories = Object.keys(groupCategories);

  const mapCategories = uniqCategories.map((category, i) => {
    const totalCount = groupCategories[category];
    const duration = VIDEO_DURATION[videoDuration];
    const DurationIcon = duration ? duration.icon : null;

    return (
      <Row
        key={category}
        type="flex"
        className={uniqCategories.length !== i - 1 && 'divider'}
        align="middle"
      >
        <Col span={12} className="style-category">
          <p className="title">{CATEGORY_TEXT[category]}</p>
          <p className="description">{`${totalCount} ${categoryType(category, totalCount)}`}</p>
          {(duration || contentLayout) && (
            <div className="video-details-wrapper">
              {duration && (
                <Col span={6} className="video-details">
                  <p className="label">{duration.text}</p>
                  <DurationIcon style={{ width: '22px', height: '27.39px' }} />
                </Col>
              )}
              {contentLayout && (
                <Col span={6} className="video-details">
                  <p>{`${contentLayout} layout`}</p>
                  <div className={`layout-shape layout-shape--${contentLayout}`} />
                </Col>
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  });

  return <div id="campaignStyleCategory">{mapCategories}</div>;
};

export default StyleCategory;
