import Video from 'components/Common/Video';
import { useMediaViewer } from 'components/Common/MediaViewer';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

/**
 * Creator Content Admin Card Component
 *
 * @typedef {{ creatorContentUID: string, contentUID: string, onDownload: (creatorContentUID:string, contentUID: string) => Promise<any> }} AdminCardProps
 * @type {React.FC<AdminCardProps>}
 */
const AdminCard = ({ creatorContentUID, contentUID, onDownload }) => {
  const creatorContent = useSelector(selectors.getCreatorContent(creatorContentUID));
  const influencer = useSelector(selectors.getInfluencer(creatorContent.influencerUID)) || {};
  const content = creatorContent.contents[contentUID];
  const [isDownloading, setIsDownloading] = useState(false);
  const { setModal } = useMediaViewer();

  async function handleDownload(e) {
    e.stopPropagation();
    setIsDownloading(true);
    await onDownload(creatorContentUID, contentUID);
    setIsDownloading(false);
  }

  return (
    <div
      className="influencer-card"
      onClick={() => {
        setModal({
          src: content.type === 'video' ? content.highResolutionVideo : content.highResolutionImage,
          type: content.type,
          show: true,
        });
      }}
    >
      <button className="u-button-reset influencer-img-btn">
        {content.type === 'image' ? (
          <img
            className="standardized-image"
            src={content.highResolutionImage || content.standardResolutionImage}
            alt=""
          />
        ) : (
          <Video src={content.highResolutionVideo} noPlay />
        )}
      </button>
      <div className="influencer-card-content">
        <a
          className="influencer-details"
          href={`https://www.instagram.com/${influencer.username}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="influencer-avatar" src={influencer.image} alt="" />
          <span className="influencer-details-right">
            <span>{influencer.name}</span>
            <span>
              {influencer.state}, Level {influencer.payOutTiers && influencer.payOutTiers.content}
            </span>
          </span>
        </a>
        <button
          className="u-button-reset influencer-download-btn"
          disabled={isDownloading ? 'disabled' : null}
          onClick={handleDownload}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default AdminCard;
