import React, { useState } from 'react';
import { IKImage } from 'imagekitio-react';
import { Icon, Spin } from 'antd';
import { ReactComponent as PlayButtonSVG } from 'images/play-btn.svg';
import { isNewContentServiceUrl, setContentUrlParams } from 'utils/content';
import './ImageKitImage.less';

const BASE_URL_ENDPOINT = 'https://ik.imagekit.io/vzzk789fgbt/';

// interface ImageKitImageProps {
//   path: string;
//   alt?: string;
//   lazyLoad?: boolean;
//   lqip?: boolean;
//   transformations: Array<Record<string, any>>;
// }

// ImageKitImage: React.FC<ImageKitImageProps>
const ImageKitImage = ({
  path,
  videoPreview = false,
  lazyLoad = true,
  lqip = true,
  transformation,
  ...imgProps
}) => {
  const [loaded, setLoaded] = useState(false);
  const ikErrorPath = (path || '').includes('.appspot.com//') || (path || '').includes('i.imgur');
  if (typeof path === 'undefined' || ikErrorPath) {
    // There are some paths that are saved in the bucket as `//influencer/...` instead of the correct `/influencer/...`
    // ImageKit does not properly handle those paths, so we use the regular Image component
    return <img className="standardized-image" src={path} alt="" />;
  }
  let urlEndpoint = BASE_URL_ENDPOINT;
  let cleanedPath = path;
  const isAws = path.indexOf('amazonaws.com');
  const isTrendCDN = isNewContentServiceUrl(path);
  if (isTrendCDN) {
    cleanedPath = setContentUrlParams(path, { thumbnail: videoPreview });
  } else if (isAws >= 0) {
    // Handle path if image is on new AWS Bucket
    cleanedPath = path.substring(isAws + 14);
    if (path.includes('staging')) {
      urlEndpoint += 'staging/';
    }
  } else {
    // Handle path if image is on legacy GCP Storage
    const gcpPathIndex = path.indexOf('appspot.com');
    cleanedPath = path.substring(gcpPathIndex + 13, path.indexOf('?'));
    if (path.includes('development')) {
      urlEndpoint += 'legacy-staging/';
    } else {
      urlEndpoint += 'legacy/';
    }
  }

  if (videoPreview) {
    cleanedPath += '/ik-thumbnail.jpg';
  }

  cleanedPath = decodeURIComponent(cleanedPath);

  return (
    <div className="video-loader">
      <span className="image-kit-container">
        {isTrendCDN ? (
          <img
            className="standardized-image"
            src={cleanedPath}
            alt=""
            onLoad={() => setLoaded(true)}
          />
        ) : (
          <IKImage
            urlEndpoint={urlEndpoint}
            path={cleanedPath}
            loading={lazyLoad ? 'lazy' : undefined}
            lqip={{ active: lqip }}
            transformation={transformation}
            onLoad={() => {
              setLoaded(true);
            }}
            onError={() => "this.style.display='none'"}
            {...imgProps}
          />
        )}

        {loaded && videoPreview && (
          <div className="video-player-action-container">
            <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
          </div>
        )}
        {!loaded && <Spin />}
      </span>
    </div>
  );
};

export default ImageKitImage;
