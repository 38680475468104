import React from 'react';

/**
 *
 * @typedef {{ product: import("components/Session/Context").Product}} GenderProps
 * @type {React.FC<GenderProps>}
 */
const Gender = ({ product }) => {
  const { gender } = product;

  return (
    <>
      <h4 className="side-sub-header-text">Gender</h4>
      <p className="side-text-description side-text-description--capitalize">{gender || 'Any'}</p>
    </>
  );
};

export default Gender;
