import { Button, Form as AntForm, Row, Col, Input, Icon, Upload } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as AddAttachmentIconWithMedia } from '../../images/add-attachment-withmedia.svg';
import { ReactComponent as AddAttachmentIconEmpty } from '../../images/add-attachment-empty.svg';

const { TextArea } = Input;

/**
 * @typedef {{ onSubmit: (text: string) => Promise<any>}} FormProps
 * @type {React.FC<FormProps & import('antd/lib/form').FormProps>}
 */
const Form = ({ form, onSubmit }) => {
  const [submiting, setSubmitting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form;
  const [files, setFiles] = useState([]);

  function handleSubmit() {
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setSubmitting(true);
        await onSubmit(values.text, files);
        resetFields();
        setSubmitting(false);
        setFiles([]);
      }
    });
  }

  function handleRemoveAttachment(index) {
    setFiles(files => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }

  const props = {
    accept: 'image/*,video/*',
    beforeUpload: (_file, fileList) => {
      setFiles(files.concat(fileList));
    },
    action: '',
  };

  const onKeyDown = e => {
    if (['Backspace', 'Delete'].includes(e.key)) {
      const currentText = form.getFieldValue('text');
      if (currentText === '') {
        const newList = files.slice(0, files.length - 1);
        setFiles(newList);
      }
    }
  };

  const filesMapped = React.useMemo(() => {
    return files.map((file, index) => {
      const binaryString = URL.createObjectURL(file);
      return (
        <div className="message-attachment" key={`${file.name}-${new Date().getTime()}`}>
          <Button
            className="message-attachment-close"
            onClick={() => handleRemoveAttachment(index)}
          >
            &times;
          </Button>
          <div className="message-attachment-file">
            {file.type.includes('video') ? (
              <video
                id={`video-item-${file.name}`}
                src={binaryString}
                controls={false}
                height="100%"
                width="100%"
                style={{ objectFit: 'cover' }}
              />
            ) : (
              <img
                height="100%"
                width="100%"
                style={{ objectFit: 'contain' }}
                src={binaryString}
                alt=""
              />
            )}
          </div>
        </div>
      );
    });
  }, [files]);

  return (
    <Row
      style={{ backgroundColor: '#F1F5FD' }}
      align="middle"
      gutter={10}
      type="flex"
      justify="space-around"
    >
      <div
        className="min-layout-content"
        style={{
          minHeight: '64px',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderRadius: 5,
          boxShadow: '-6px 6px 11px #D0DAE7',
          padding: '10px 20px',
        }}
      >
        <Col
          style={{ padding: 0, display: 'flex', flexDirection: 'row' }}
          className="trend-message-input"
          span={24}
        >
          {filesMapped}
          <AntForm
            style={{
              marginRight: 12,
              borderRadius: 0,
              display: 'flex',
              alignSelf: 'center',
              width: '80%',
            }}
            form={form}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {getFieldDecorator('text', {
              rules: [{ required: files.length <= 0, message: 'Required' }],
            })(
              <TextArea
                className="trend-chat-input"
                autoSize
                type="text"
                size="large"
                placeholder="Type a Message..."
                style={{
                  resize: 'none',
                  color: '#000',
                  borderRadius: 0,
                  border: 'none',
                }}
                onKeyDown={onKeyDown}
              />
            )}
          </AntForm>
          <div className="input-actions">
            <Upload className="upload-btn" showUploadList={false} multiple {...props}>
              <Button
                id="add-attachment-btn"
                size="large"
                shape="circle"
                style={{
                  backgroundColor: `${files.length > 0 || isHovered ? '#5E718B' : '#ECF4FD'}`,
                  color: '#5E718B',
                  border: 0,
                }}
                onMouseOver={e => {
                  e.preventDefault();
                  setIsHovered(true);
                }}
                onMouseOut={e => {
                  e.preventDefault();
                  setIsHovered(false);
                }}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
              >
                <Icon
                  style={{ fontSize: 28, color: '#5E718B' }}
                  component={
                    files.length > 0 || isHovered
                      ? AddAttachmentIconWithMedia
                      : AddAttachmentIconEmpty
                  }
                />
              </Button>
            </Upload>
            <Button
              className="trend-btn"
              loading={submiting}
              onClick={handleSubmit}
              size="large"
              style={{
                color: '#000',
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: 14,
                alignSelf: 'flex-end',
              }}
            >
              Send
            </Button>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default AntForm.create({ name: 'message-form' })(Form);
