import React from 'react';
import { IMPRESSIONS } from 'constants/posts';

/**
 * @typedef {{
 *  creators: number,
 *  activePosts: number,
 *  requiredPosts: number,
 *  likes: number,
 *  comments: number,
 * }} StatsProps
 * @type {React.FC<StatsProps>}
 */
const Stats = ({ creators, activePosts, requiredPosts, likes = 0, comments = 0 }) => {
  const impressions = likes * IMPRESSIONS;

  return (
    <section className="campaign-stats">
      <div>
        <h2 className="stats-heading">{creators.toLocaleString('en', { useGrouping: true })}</h2>
        <p>Creators</p>
      </div>
      <div>
        <h2 className="stats-heading">
          {activePosts.toLocaleString('en', { useGrouping: true })}/
          {requiredPosts.toLocaleString('en', { useGrouping: true })}
        </h2>
        <p>Live Posts</p>
      </div>
      <div>
        <h2 className="stats-heading">{likes.toLocaleString('en', { useGrouping: true })}</h2>
        <p>Likes</p>
      </div>
      <div>
        <h2 className="stats-heading">{comments.toLocaleString('en', { useGrouping: true })}</h2>
        <p>Comments</p>
      </div>
      <div>
        <h2 className="stats-heading">{impressions.toLocaleString('en', { useGrouping: true })}</h2>
        <p>Impression</p>
      </div>
    </section>
  );
};

export default Stats;
