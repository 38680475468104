import { Spin, Row, Col, Icon } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { ReactComponent as PlayButtonSVG } from 'images/play-btn.svg';

/**
 * Video Component
 *
 * @typedef {{
 *    src: string,
 *    autoPlay?: boolean
 *    noPlay?: boolean
 * }} VideoProps
 * @type {React.FC<VideoProps>}
 */
const Video = ({ src, autoPlay, noPlay }) => {
  const vidRef = createRef();

  const [loaded, setLoaded] = useState({ [src]: false });
  const [playing, setPlaying] = useState(autoPlay || false);

  useEffect(() => {
    if (src === undefined) {
      setPlaying(false);
    }
  }, [src]);

  useEffect(() => {
    if (loaded && vidRef) {
      vidRef.current.addEventListener('ended', event => {
        // eslint-disable-next-line no-param-reassign
        event.target.currentTime = 0;
        setPlaying(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vidRef]);

  function togglePlay() {
    if (noPlay) {
      return;
    }
    if (vidRef.current) {
      setPlaying(play => {
        if (!play) {
          vidRef.current.play();
        } else {
          vidRef.current.pause();
        }
        return !play;
      });
    }
  }

  return (
    <div className="video-loader" role="button" tabIndex={-1} onClick={togglePlay}>
      {!playing && loaded[src] && (
        <Row
          justify="center"
          align="middle"
          type="flex"
          className="video-player-actions"
          style={{ zIndex: 0 }}
        >
          <Col>
            <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
          </Col>
        </Row>
      )}
      <video
        key={src}
        ref={vidRef}
        preload="auto"
        onLoadedData={() => {
          setLoaded({ [src]: true });
        }}
        autoPlay={autoPlay}
      >
        <source src={src} type="video/mp4" />
        <source src={src} type="video/webm" />
        <source src={src} type="video/ogg" />
        <source src={src} type="video/avi" />
        Sorry, your browser doesn’t support embedded videos.
      </video>
      {!loaded[src] && <Spin />}
    </div>
  );
};

export default Video;
