import React from 'react';

const EmptyCampaign = ({ copy, heading, image }) => (
  <article className="empty-campaign">
    <img src={image} alt="" />
    <h2>{heading}</h2>
    <p>{copy}</p>
  </article>
);

export default EmptyCampaign;
