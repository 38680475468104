import React from 'react';

const oldGuide = guidelines => (
  <p className="text-description" style={{ whiteSpace: 'pre-line' }}>
    {guidelines}
  </p>
);

const newGuide = (postGuidelines = []) => {
  const GUIDELINE_LABELS = [
    'Which products will you send the creators?',
    'Content Style Request',
    'Caption Details',
  ];

  return postGuidelines.map((guidelines, index) => (
    <div key={GUIDELINE_LABELS[index]}>
      <p className="header-text">{GUIDELINE_LABELS[index]}</p>
      <p className="text-description" style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {guidelines}
      </p>
    </div>
  ));
};

/**
 * Post Guideline Component
 *
 * @typedef {{ product: import('components/Session/Context').Product}} PostGuidelinesProps
 * @type {React.FC<PostGuidelinesProps>}
 */
const PostGuidelines = ({ product }) => {
  const { postGuidelines, guidelines } = product;
  const finalGuideline = postGuidelines || guidelines;

  return (
    <div className="post-guidelines">
      {Array.isArray(finalGuideline) ? newGuide(finalGuideline) : oldGuide(finalGuideline)}
    </div>
  );
};

export default PostGuidelines;
