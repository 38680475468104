import React from 'react';
import moment from 'moment';
import { Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { ReactComponent as IconSettings } from 'images/icon-settings.svg';
import { ReactComponent as IconSoona } from 'images/soona/wink.svg';
import { ReactComponent as IconStar } from 'images/ContentShowcase/ContentShowcaseTabIcons/StarIcon.svg';
import * as routes from 'constants/routes';
import { useSession } from 'components/Session';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import CampaignsSideMenu from './CampaignsSideMenu';
import SidebarLink from '../SidebarLink';

const CampaignMenus = ({ onNavigate }) => {
  const {
    brand,
    activeProductUIDs: activeProducts = [],
    completedProductUIDs: completedProducts = [],
  } = useSession();
  const products = useSelector(selectors.getProducts());
  const ownedCampaigns = activeProducts;
  const { isAdmin, isContentAdmin } = brand;
  const { status, stripeCustomerId, lastPurchaseDate, approvedAt } = brand;
  const incomplete = !stripeCustomerId;
  const firstCampaign = products[ownedCampaigns[0]];
  const firstCampaignCompleted = firstCampaign && !incomplete;
  const onBoarding = status && !lastPurchaseDate;
  const onBoardingApproved = onBoarding && status === 'approved';
  const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

  const onBoardingActive =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') > moment();

  const expiredOnBoardingCampaign =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') < moment();

  return (
    <div className="side-menu sidenav-main-wrapper">
      <div className="sidenav-campaigns">
        <CampaignsSideMenu onNavigate={onNavigate} />
        {!!completedProducts.length && (
          <div id="archive-link-container">
            <Link to="/campaigns/history" className="sub-menu-btn archive" onClick={onNavigate}>
              <span>Archived Projects</span>
            </Link>
          </div>
        )}
      </div>

      <ul
        className={`sidenav-footer ${(expiredOnBoardingCampaign || onBoardingActive) &&
          showMarqueNotif &&
          'with-notification'}`}
      >
        {(isAdmin || isContentAdmin) && (
          <li key="admin">
            <SidebarLink
              icon={
                <span className="sidebar-icon-wrapper">
                  <Icon type="user" />
                </span>
              }
              label="Employee Functions"
              routeUrl={routes.ADMIN_OPTIONS_PATH}
              onClick={onNavigate}
            />
          </li>
        )}
        <li key="campaigns/showcase">
          <SidebarLink
            icon={<IconStar className="sidebar-icon" />}
            label="UGC Showcase"
            routeUrl={routes.CONTENT_SHOWCASE_PATH}
            onClick={onNavigate}
          />
        </li>
        <li>
          <SidebarLink
            icon={<IconSoona className="sidebar-icon" />}
            isNew
            label="soona studio"
            routeUrl={routes.SOONA_SHOWCASE}
            onClick={onNavigate}
          />
        </li>
        <li>
          <SidebarLink
            icon={
              <span className="sidebar-icon-wrapper">
                <Icon type="tool" />
              </span>
            }
            isNew
            label="Photo AI Tools"
            routeUrl="https://soona.co/mokker?utm_source=trend&utm=referral&utm_campaign=photo_ai_tools&utm_content=trend_side_nav"
            onClick={onNavigate}
          />
        </li>
        <li>
          <SidebarLink
            icon={
              <span className="sidebar-icon-wrapper">
                <Icon type="camera" />
              </span>
            }
            isNew
            label="Studio Bookings"
            routeUrl="https://soona.co/packs?utm_source=trend&utm=referral&utm_campaign=studio_bookings&utm_content=trend_side_nav"
            onClick={onNavigate}
          />
        </li>
        <li>
          <SidebarLink
            icon={<span className="sidebar-icon-wrapper">?</span>}
            isNew
            label="Help Center"
            routeUrl="https://support.soona.co/trend-ugc"
            onClick={onNavigate}
          />
        </li>
        <li key="store/edit">
          <SidebarLink
            icon={
              <span className="sidebar-icon-wrapper">
                <IconSettings className="sidebar-icon sidebar-icon--account" />
              </span>
            }
            label="Account Settings"
            routeUrl={routes.ACCOUNT_PATH}
            onClick={onNavigate}
          />
        </li>
      </ul>
    </div>
  );
};

export default withRouter(CampaignMenus);
